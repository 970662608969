// AJOUTER DU STYLE EN FONCTION DU NAVIGATEUR

// if (BrowserDetect.browser.indexOf("chrome")>-1) {
//     document.write('<'+'link rel="stylesheet" href="../component/chromeCSSStyles.css" />');
//     } else if (BrowserDetect.browser.indexOf("mozilla")>-1) {
//         document.write('<'+'link rel="stylesheet" href="../component/mozillaStyles.css" />');
//     } else if (BrowserDetect.browser.indexOf("explorer")>-1) {
//         document.write('<'+'link rel="stylesheet" href="../component/explorerStyles.css" />');
//     }


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/*  Interpréter les balises LaTeX après avoir rafraichi la     */
/*  page en Ajax                                               */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
function renderMaths() {
    renderMathInElement(document.body, {
        delimiters: [
            {left: "$$", right: "$$", display: true},
            {left: "$", right: "$", display: false}
        ],
        errorColor: "#F00",
        throwOnError: false
        });
}
    
    
$(document).ready(function () {
    
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /*    Bouttons pour la navigation et le filtre de la page index    */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $('.filter-toggle').click(function() {
        $('body').toggleClass('filter-open');
        $('html').toggleClass('no-scroll');
    });
    
    $('.nav-toggle').click(function() {
        $('body').toggleClass('nav-open')
    });

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /* Le filtre passe en mode plein écran si ecran < $unit-md (840px) */
    /* Le filtre est accessible par un bouton flottant                 */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    if($(window).width() <= 840){
        $('#form-filters-wrapper').toggleClass('overlay-filter')
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /* Le bouton submit reseigne l'historique de naviagtion / remonte  */
    /* en haut de page / soumet le formulaire / ferme le filtre        */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $('#buttons-filters .btn-submit').click(function(e){
            e.preventDefault();
            history.replaceState(null, null, window.location.pathname);
            $("html, body").animate({scrollTop: 0}, 400);
            $('#form-filters').submit();
            $('html').toggleClass('no-scroll');
            $('body').toggleClass('filter-open')
        });

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /* Le bouton reset, réinitialise le filtre, puis soumet la requête */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $('#buttons-filters .btn-reset').click(function(e){
        e.preventDefault();
        $('#form-filters').get(0).reset();
        $('#form-filters').submit();
    });

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /* si affichage > $unit-md (840px), le formulaire du filtre est    */
    /* soumis après chaque sollicitation                               */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $(".filters input[type='checkbox'], .filters input[type='radio']").click(function (e) {
        if($(window).width() > 840){
            history.replaceState(null, null, window.location.pathname);
            // $("html, body").animate({scrollTop: 0}, 400);
            $('#form-filters').submit();
        }
    });
 

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /*          Actualiser le label des difficultés du filtre      */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $(".filters input[type='radio']+label").click(function (e) {
        $('#star-value').html($(this).attr('title'));
    });

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /*                  Minimiser certains filtres                 */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $(".toggle").click(function (e) {
        let $body = $(this).parent().find('.filters-item-body');
        if ($(this).hasClass('toggle-active')) {
            $(this).removeClass('toggle-active');
            $body.slideDown(300);
        } else {
            $(this).addClass('toggle-active');
            $body.slideUp(300);
        }
    });
 
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    /*               Redimensionement de la fenêtre                */
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
    $(window).resize(function() {
        if($(window).width() <= 840){
            if(!$('#form-filters-wrapper').hasClass('overlay-filter')){
                $('#form-filters-wrapper').addClass('overlay-filter')
            }
        } else {
            if($('#form-filters-wrapper').hasClass('overlay-filter')){
                $('#form-filters-wrapper').removeClass('overlay-filter')
            }
        }
    });


});
